import { DropRecord } from "@pro/common/contracts/atomicdropsx";
import { EosAsset } from "@pro/common/eos";
import { action, makeObservable, observable } from "mobx";

export class DropModel
{
	_record!: DropRecord;
	_price!: EosAsset;

	constructor(_record: DropRecord)
	{
		this.update(_record);

		makeObservable(this, {
			_record: observable,
			update: action,
		});
	}

	update(record: DropRecord)
	{
		this._record = record;
		this._price = EosAsset.parse(this._record.listing_price);
	}

	get dropId()
	{
		return this._record.drop_id;
	}

	get templateId()
	{
		return this._record.assets_to_mint[0].template_id;
	}

	get currentClaimed()
	{
		return this._record.current_claimed;
	}

	get maxClaimable()
	{
		return this._record.max_claimable === 0
			? 1_000_000
			: this._record.max_claimable;
	}

	get availableToClaim()
	{
		return this.maxClaimable - this.currentClaimed;
	}

	get price()
	{
		return this._price;
	}
}