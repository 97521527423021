import { action, makeObservable, observable } from "mobx";

export class LoadStatusModel
{
	status: LoadStatus = LoadStatus.NOT_STARTED;

	constructor() {
		makeObservable(this, {
			status: observable,
			setNotStarted: action,
			setLoading: action,
			setLoaded: action,
		});
	}

	setNotStarted() {
		this.status = LoadStatus.NOT_STARTED;
	}

	setLoading() {
		this.status = LoadStatus.LOADING;
	}

	setLoaded() {
		this.status = LoadStatus.LOADED;
	}

	get isNotStarted()
	{
		return this.status === LoadStatus.NOT_STARTED;
	}
}

export enum LoadStatus
{
	NOT_STARTED,
	LOADING,
	LOADED,
}
