export const L = {
	siteName: "Tribal Books",
	common: {
		stake: "Stake",
		unstake: "Unstake",
		packs: "Packs",
		books: "Books",
		heroes: "Heroes",
		symbols: "Symbols",
		success: "Success",
	},
	menu: {
		aboutMine: "About mine",
		buyPacks: "Buy packs",
		logout: "Logout",
	},
	rarity: {
		common: "Common",
		rare: "Rare",
		unique: "Unique",
		epic: "Epic",
		legendary: "Legendary",
		mythic: "Mythic",
	},
	page: {
		main: "Main",
		cards: "My Cards",
		books: "My Books",
		heroes: "My heroes",
		gameHome: "Home",
		gameCards: "My Cards",
		gameMarket: "Market",
		gameExchange: "Exchange",
		legend: "Legend",
	},
	cookieConsect: {
		text: "This website uses cookies to enhance the user experience.",
		button: "I understand",
	},
	specialEditionPage: {
		title: "My Special edition NFTs",
		desc: "Your special edition NFTs are stored here. These exclusive sets of books and cards are supplementary of the main collections. Special edition books and card sets are minted in a limited quantity and are reserved for partnerships and giveaways especially for the most devoted members of our tribe.",
	},
	packsPage: {
		title: "Unpacking",
		desc: "All your packs of the Tribal Books assets are safely stored in this secret chamber, guarded by devoted spirits. Unpack them to learn what you’ve got.",
	},
	buyPackPage: {
		title: "Buy Pack",
		desc: "Purchase the packs of Symbols here. Execute an age-old ritual above each of your packs to unpack them. After that, the assets will appear on the appropriate page (Books or Cards).",
		successMessage: "Congratulations! You have successfully purchased the pack.",
	},
	gameHome: {
		title: "Home",
		desc: "Play",
		enchant: "Enchant",
		enchant_all: "Enchant All",
		rarity: "Rarity",
		cargeTime: "Charge Time",
		enchantingPower: "Enchanting Power",
		trible: "Trible",
		name: "Name",
	},
	gameCards: {
		title: "Cards",
		desc: "Cards",
		unpack: "Unpack",
	},
	gameMarket: {
		title: "Market",
		desc: "Market",
		buy: "Buy",
		packTitle: "Hero Pack",
	},
	manaEnchanting: {
		title: {
			mana: "MANA",
			how_to_enchant: "How to enchant the mana",
			call_for_the_hero: "Call for the Hero",
			cards: "Cards",
		},
		enchant: "ENCHANT",
		play: "PLAY",
		the_next_phase: "The next phase",
		the_mana_enchanting: "The Mana Enchanting",
		the_mana_description: "Mana is the magical essence used in many ancient rituals within Tribal Books. In particular, the Ritual of Forces, which will finally reveal the gaming numbers to all your symbols. You may also use Mana in purchasing new assets in game.",
		the_avalible_amount: "The available amount of mana in the Universe is limited.",
		stake: "Stake",
		your_ancient: "your ancient symbols and books.",
		the_amount_mana: "The amount of mana you receive will depend on the rarity and quantity of your assets.",
		collect: "Collect",
		your_mana_regularly: "your Mana regularly.",
		your_cauldron_holds: "Your cauldron holds a limited amount of Mana, so come back every day to collect your Mana or you might squander some.",
		spend: "Spend",
		your_mana_on_pack: "your mana on a pack with a hero",
		the_hero: "The Hero belongs to one of the six ancient peoples. They will prove to be very helpful in overcoming obstacles during the game. Heroes can be staked and enchant mana as well.",
		with_this: "With this pack, you’ll receive 1 exclusive Hero NFT. Your Hero belongs to one of the six ancient peoples and knows their age old magic well. They will also prove to be very helpful in overcoming many obstacles during the course of the game.",
		follow_tribal_books: "Follow Tribal Books",
		hear_even: "Hear even the quietest whispering of ancient spirits telling the precious info.",

	},
	cards: {
		title: {
			heroes: "Heroes",
			symbols: "Symbols",
			books: "Books",
		},
		description: {
			heroes: {
				p_1: "The player has his character which develops over the course of the game. The character has energy, an option to own books, and a deck of cards. There are some specific cards, which interact only with particular types of characters.",
				p_2: "The character can upgrade during the game. They can also belong to some guilds of elements (fire, water, earth, or air). That gives them an advantage on some segments of the path.the forces stored there.",
			},
			symbols: {
				p_1: "The player uses a card from his own deck to make a move. He forms the deck for each round separately before the game starts by combining the cards he owns before the round. To use the card, the player burns the number of energy points listed on this card. After the player uses the card for a move, the used card goes back to the deck. Before the game starts.",
			},
			books: {
				p_1: "Tribal books are all-powerful artifacts, the unique storages for mighty forces of ancient nations. All together they are the key that could return forgotten greatness and power to the heart-cities, and open their treasures to those who dare to step on the dangerous path. On this path you will come across not only human adversaries but ancient spirits wielding magic as well.  Be very carefu",
				p_2: "A Tribal Book is just a book until it is able to produce its magical forces. Yet all magical forces of each Tribe are tied to an appropriate Tribal Book by an ancient connection. By playing and winning, the user achieves the knowledge which is a key to bring forth the next magic force of the Tribes' Ancient Book. Each Tribal Book by itself is an NFT-asset, so you can trade or sell it, with all the magical forces that you have stored there.",
			},
		},
	},
};
