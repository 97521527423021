import { IAssetJson, TRarity } from "@pro/common/conf";
import { TUint64 } from "@pro/common/contracts/atomicassets";
import { action, makeObservable, observable } from "mobx";
import { IAssetData } from "./AssetData";
import { BookModel } from "./BookModel";

export class BookTable
{
	_items = new Map<TUint64, BookModel>();

	constructor()
	{
		makeObservable(this, {
			_items: observable,
			updateItem: action,
			removeItem: action,
		});
	}

	updateItem(record: IAssetData, data: IAssetJson)
	{
		let item = this._items.get(record.asset_id);
		if (!item) {
			const model = new BookModel(record, data);
			this._items.set(model.assetId, model);
		}
	}

	removeItem(assetId: TUint64)
	{
		this._items.delete(assetId);
	}

	find(predicate: (it: BookModel) => boolean): BookModel | undefined
	{
		for (let item of this._items.values()) {
			if (predicate(item))
				return item;
		}
	}

	query(predicate: (it: BookModel) => boolean): BookModel[]
	{
		let result = [];
		for (let item of this._items.values()) {
			if (predicate(item))
				result.push(item);
		}
		return result;
	}

	queryAll(): BookModel[]
	{
		return this.query(() => true);
	}

	queryFromAssetId(assetId: TUint64)
	{
		return this.query(it => it.assetId > assetId);
	}

	queryByTemplateId(templateId: number): BookModel[]
	{
		return this.query(it => it.templateId === templateId);
	}

	queryByRarity(rarity: TRarity): BookModel[]
	{
		return this.query(it => it.rarity === rarity);
	}
}
