exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-books-tsx": () => import("./../../../src/pages/books.tsx" /* webpackChunkName: "component---src-pages-books-tsx" */),
  "component---src-pages-buy-pack-tsx": () => import("./../../../src/pages/buy-pack.tsx" /* webpackChunkName: "component---src-pages-buy-pack-tsx" */),
  "component---src-pages-cards-tsx": () => import("./../../../src/pages/cards.tsx" /* webpackChunkName: "component---src-pages-cards-tsx" */),
  "component---src-pages-game-cards-tsx": () => import("./../../../src/pages/game/cards.tsx" /* webpackChunkName: "component---src-pages-game-cards-tsx" */),
  "component---src-pages-game-home-tsx": () => import("./../../../src/pages/game/home.tsx" /* webpackChunkName: "component---src-pages-game-home-tsx" */),
  "component---src-pages-game-market-tsx": () => import("./../../../src/pages/game/market.tsx" /* webpackChunkName: "component---src-pages-game-market-tsx" */),
  "component---src-pages-heroes-tsx": () => import("./../../../src/pages/heroes.tsx" /* webpackChunkName: "component---src-pages-heroes-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mana-enchanting-tsx": () => import("./../../../src/pages/mana_enchanting.tsx" /* webpackChunkName: "component---src-pages-mana-enchanting-tsx" */),
  "component---src-pages-packs-tsx": () => import("./../../../src/pages/packs.tsx" /* webpackChunkName: "component---src-pages-packs-tsx" */),
  "component---src-pages-quest-tsx": () => import("./../../../src/pages/quest.tsx" /* webpackChunkName: "component---src-pages-quest-tsx" */),
  "component---src-pages-special-edition-tsx": () => import("./../../../src/pages/special-edition.tsx" /* webpackChunkName: "component---src-pages-special-edition-tsx" */),
  "component---src-pages-unpacking-tsx": () => import("./../../../src/pages/unpacking.tsx" /* webpackChunkName: "component---src-pages-unpacking-tsx" */)
}

