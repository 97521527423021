import { TRarity } from "@pro/common/conf";

const tag = "[audio]";
const AUDIO_URL = "https:///static.tribalbooks.io/audio/";

export class AudioService
{
	static getFxURL(name: string)
	{
		return `${AUDIO_URL}unpacking_fx/${name}.mp3`;
	}

	private _fxLoaded = false;

	private _bgUnpack = new Audio(`${AUDIO_URL}unpacking_bg/unpacking.mp3`);

	private _fxByRare = {
		common: [
			new Audio(AudioService.getFxURL("common01")),
			new Audio(AudioService.getFxURL("common02")),
			new Audio(AudioService.getFxURL("common03")),
			new Audio(AudioService.getFxURL("common04")),
		],
		rare: [
			new Audio(AudioService.getFxURL("rare01")),
			new Audio(AudioService.getFxURL("rare02")),
			new Audio(AudioService.getFxURL("rare03")),
			new Audio(AudioService.getFxURL("rare04")),
		],
		unique: [
			new Audio(AudioService.getFxURL("unique01")),
			new Audio(AudioService.getFxURL("unique02")),
			new Audio(AudioService.getFxURL("unique03")),
			new Audio(AudioService.getFxURL("unique04")),
		],
		epic: [
			new Audio(AudioService.getFxURL("epic01")),
			new Audio(AudioService.getFxURL("epic02")),
			new Audio(AudioService.getFxURL("epic03")),
			new Audio(AudioService.getFxURL("epic04")),
		],
		legendary: [
			new Audio(AudioService.getFxURL("legendary")),
		],
		mythic: [
			new Audio(AudioService.getFxURL("mythic")),
		],
	};

	async loadAllFx()
	{
		if (this._fxLoaded)
			return;

		let promises = [];

		const allFx = ([] as HTMLAudioElement[]).concat(...(Object.values(this._fxByRare)));
		for (let audio of allFx) {
			let promise = new Promise((resolve, reject) => {
				audio.addEventListener("loadeddata", resolve);
				audio.addEventListener("error", reject);
				audio.load();
			});
			promises.push(promise);
		}
		await Promise.all(promises);

		this._fxLoaded = true;
	}

	playUnpackFx(rarity: TRarity)
	{
		let allRarityFx = this._fxByRare[rarity];
		const fx = allRarityFx[Math.floor(Math.random() * allRarityFx.length)];
		this.playFx(fx);
	}

	private playFx(audio: HTMLAudioElement)
	{
		if (!this._fxLoaded)
			return;
		audio.currentTime = 0;
		audio.play()
			.catch(e => console.error(tag, e));
	}

	playBg()
	{
		this._bgUnpack.volume = 0.5;
		this._bgUnpack.currentTime = 0;
		this._bgUnpack.play()
			.catch(e => console.error(tag, e));
	}

	stopBg()
	{
		this._bgUnpack.pause();
	}

	get fxLoaded(): boolean
	{
		return this._fxLoaded;
	}
}