import { IAssetJson, IPackJson, TRarity, TSchemaName } from "@pro/common/conf";
import { AAsset } from "@pro/common/contracts/atomicassets";
import { PackRecord } from "@pro/common/contracts/tribalbooks";
import { EosAsset } from "@pro/common/eos";
import { toJson } from "@pro/common/utils";
import { action, makeObservable, observable } from "mobx";
import * as React from "react";
import { app } from "../App";
import { IAssetData } from "./AssetData";
import { BookModel } from "./BookModel";
import { BookTable } from "./BookTable";
import { DropTable } from "./DropTable";
import { HeroModel } from "./HeroModel";
import { HeroTable } from "./HeroTable";
import { LoadStatusModel } from "./LoadStatusModel";
import { PackTable } from "./PackTable";
import { StakedAssetModel } from "./StakedAssetModel";
import { StakedAssetTable } from "./StakedAssetTable";
import { SymbolModel } from "./SymbolModel";
import { SymbolTable } from "./SymbolTable";
import { UnpackModel } from "./UnpackModel";

export class AppStore
{
	logined = false;
	userName = "";
	packs = new PackTable();
	books = new BookTable();
	symbols = new SymbolTable();
	heroes = new HeroTable();
	drops = new DropTable();
	stakedAssets = new StakedAssetTable();
	unpacking: UnpackModel | null = null;
	balance = 0;
	mana_balance = 0;
	poolBalance = 0;
	readonly loadStatus = new LoadStatusModel();

	activeCard: StakedAssetModel | null = null;
	activeDropdown: TRarity | 'all' = 'all';
	activeTab: TSchemaName = 'symbols';

	constructor()
	{
		makeObservable(this, {
			logined: observable,
			userName: observable,
			packs: observable,
			books: observable,
			symbols: observable,
			drops: observable,
			unpacking: observable,
			balance: observable,
			mana_balance: observable,
			poolBalance: observable,
			activeTab: observable,
			activeCard: observable,
			activeDropdown: observable,
			login: action,
			logout: action,
			setAssets: action,
			setUnpacking: action,
			removeUnpacking: action,
			setBalance: action,
			setPoolBalance: action,
			setActiveTab: action,
			setActiveCard: action,
			setActiveDropdown: action,
		});
	}

	login(userName: string)
	{
		this.userName = userName;
		this.logined = true;
	}

	logout()
	{
		this.userName = "";
		this.packs = new PackTable();
		this.books = new BookTable();
		this.symbols = new SymbolTable();
		this.symbols = new SymbolTable();
		this.unpacking = null;
		this.balance = 0;
		this.logined = false;

		this.loadStatus.setNotStarted();
	}

	setAssets(assets: IAssetData[])
	{
		for (const asset of assets) {

			let template = app.assetConf.findByTemplateId(asset.template_id);
			if (!template) {
				console.error(`template not found: ${toJson(asset)}`);
				continue;
			}

			switch (asset.schema_name as TSchemaName) {
				case "packs":
					this.packs.updateItem(asset, template as IPackJson);
					break;
				case "books":
					this.books.updateItem(asset, template as IAssetJson);
					break;
				case "symbols":
					this.symbols.updateItem(asset, template as IAssetJson);
					break;
				case "heroes":
					this.heroes.updateItem(asset, template as IAssetJson);
					break;
			}
		}
	}

	queryBySchemaAndRarity(schemaName: TSchemaName, rarity: TRarity): BookModel[] | SymbolModel[] | HeroModel[]
	{
		switch (schemaName) {
			case "books":
				return this.books.queryByRarity(rarity)
			case "symbols":
				return this.symbols.queryByRarity(rarity);
			case "heroes":
				return this.heroes.queryByRarity(rarity);
			default:
				throw Error("Invalid schemaName");
		}
	}

	setUnpacking(pack: PackRecord, assetRecord: AAsset)
	{
		if (!pack)
			return;

		let packData = app.assetConf.packs.find(it => it.template_id === assetRecord.template_id);
		if (packData) {
			this.unpacking = new UnpackModel(pack, assetRecord, packData);
		}
	}

	removeUnpacking()
	{
		if (!this.unpacking)
			return;
		this.packs.removeItem(this.unpacking.pack.asset_id);
		this.unpacking = null;
	}

	setBalance(balance: EosAsset)
	{
		switch (balance.symbol.code)
		{
			case app.chainConf.SYS_TOKEN.code:
			{
				this.balance = balance.amount;
				break;
			}
			case app.chainConf.MANA_TOKEN.code:
			{
				this.mana_balance = balance.amount;
				break;
			}
		}
	}

	setPoolBalance(balance: EosAsset)
	{
		this.poolBalance = balance.amount;
	}

	setActiveCard = (activeCard: StakedAssetModel | null) => {
		this.activeCard = activeCard;
	}

	setActiveDropdown = (activeDropdown: TRarity | 'all') => {
		this.activeDropdown = activeDropdown;
	}

	setActiveTab = (activeTab: TSchemaName) => {
		this.activeTab = activeTab;
	}
}
