import { User } from "universal-authenticator-library";
import { appStore, world } from "../App";

export class UalService
{
	private _ual?: IUal;
	private _isDevAuth = false;

	init(ual: IUal)
	{
		this._ual = ual;
	}

	logout()
	{
		this._ual?.logout();
		appStore.logout();
	}

	async login()
	{
		try {
			return this._ual?.showModal();
		}
		catch (e) {
			console.log("login error:", e);
		}
	}

	async devAuth()
	{
		const url = new URL(window.location.href);
		const devAuth = url.searchParams.get("dev_auth");
		if (devAuth)
		{
			this._isDevAuth = true;
			await appStore.login(devAuth);
			await world.updateBalance();
			return;
		}
	}

	get isDevAuth(): boolean
	{
		return this._isDevAuth;
	}
}

export interface IUal
{
	activeUser?: User;
	showModal(): void;
	logout(): void;
}
