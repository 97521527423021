import { useEffect } from "react";
import { withUAL } from "ual-reactjs-renderer";
import { User } from "universal-authenticator-library";
import { appStore, eos, ualService } from "../App";
import { L } from "../l10n/L10n";
import { IUal } from "../services/UalService";

const UalView = withUAL((
	{
		ual,
	}: {
		ual: IUal,
	}
) => {
	useEffect(() => ualService.init(ual), []);

	useEffect(() => {
		if (ualService.isDevAuth)
			return;

		if (ual.activeUser)
		{
			loginWithUal(ual.activeUser).catch();
		}
		else {
			if (appStore.logined)
				appStore.logout();
		}
	}, [ual.activeUser]);

	const loginWithUal = async (activeUser: User) => {
		const userName = await activeUser.getAccountName();
		if (userName !== appStore.userName)
		{
			await eos.loginWithUal(activeUser);
			appStore.login(userName);
		}
	};

	return null;
});

UalView.displayName = L.siteName;

export default UalView;