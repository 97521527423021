import { IPackJson } from "@pro/common/conf";
import { AAsset } from "@pro/common/contracts/atomicassets";
import { PackRecord } from "@pro/common/contracts/tribalbooks";
import { action, computed, makeObservable, observable } from "mobx";
import { BookModel } from "./BookModel";
import { HeroModel } from "./HeroModel";
import { SymbolModel } from "./SymbolModel";

export class UnpackModel
{
	assetsObtained = false;
	symbols: SymbolModel[] = [];
	books: BookModel[] = [];
	heroes: HeroModel[] = [];

	constructor(private _pack: PackRecord,
	            private _assetRecord: AAsset,
	            private _packData: IPackJson)
	{

		makeObservable(this, {
			assetsObtained: observable,
			symbols: observable,
			books: observable,
			setObtainedAsset: action,
			cards: computed,
		});
	}

	setObtainedAsset(symbols: SymbolModel[], books: BookModel[], heroes: HeroModel[])
	{
		this.symbols = symbols;
		this.books = books;
		this.heroes = heroes;
		this.assetsObtained = true;
	}

	get pack(): PackRecord
	{
		return this._pack;
	}

	get packImg(): string
	{
		return this._packData.img;
	}

	get cards()
	{
		return (this.symbols as Array<SymbolModel | BookModel | HeroModel>).concat(this.books).concat(this.heroes);
	}
}