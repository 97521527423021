const tag = "[backend]";

export class BackendService
{
	readonly UNAUTHORIZED = "B_UNAUTHORIZED";

	constructor(private backendUrl: string) {}

	async unpack(account: string)
	{
		return this.call("unpack", {account});
	}

	private async call<T>(path: string, params: object = {}): Promise<T>
	{
		let timeMarker = Date.now();
		let method = "POST";
		let url = `${this.backendUrl}/api/${path}`;
		console.log(tag, `<- ${method} ${url}`);

		let headers: Record<string, string> = {
			"Content-Type": "application/json;charset=UTF-8",
		};

		let response = await fetch(url, {
			method: method,
			headers: headers,
			credentials: "include",
			body: JSON.stringify(params),
		});

		if (response.status === 503
		    && response.headers.get("content-type")?.includes("text/html")) {
			console.log(tag, "reload page to pass cloudflare challenge");
			location.reload();
		}

		let json = await response.json();

		if (response.status === 200 && json.result !== undefined) {
			console.debug(tag, `-> ${method} ${url} ${Date.now() - timeMarker}ms`);
			return json.result;
		} else if (response.status === 401) {
			console.warn(tag, "=> API Unauthorized");
			throw new Error(this.UNAUTHORIZED);
		} else if (json.error) {
			console.error(tag, `-> API Error: ${json.error}`);
			throw new Error(json.error);
		} else {
			console.error(tag, `-> Unknown API error (${path}), status: ${response.status}`);
			throw new Error("B_UNKNOWN_ERROR_STATUS_" + response.status);
		}
	}
}