import { IAssetJson, TRarity } from "@pro/common/conf";
import { TUint64 } from "@pro/common/contracts/atomicassets";
import { action, makeObservable, observable } from "mobx";
import { IAssetData } from "./AssetData";
import { HeroModel } from "./HeroModel";

export class HeroTable
{
	_items = new Map<TUint64, HeroModel>();

	constructor()
	{
		makeObservable(this, {
			_items: observable,
			updateItem: action,
			removeItem: action,
		});
	}

	updateItem(record: IAssetData, data: IAssetJson)
	{
		let item = this._items.get(record.asset_id);
		if (!item) {
			const model = new HeroModel(record, data);
			this._items.set(model.assetId, model);
		}
	}

	removeItem(assetId: TUint64)
	{
		this._items.delete(assetId);
	}

	find(predicate: (it: HeroModel) => boolean): HeroModel | undefined
	{
		for (let item of this._items.values()) {
			if (predicate(item))
				return item;
		}
	}

	query(predicate: (it: HeroModel) => boolean): HeroModel[]
	{
		let result = [];
		for (let item of this._items.values()) {
			if (predicate(item))
				result.push(item);
		}
		return result;
	}

	queryAll(): HeroModel[]
	{
		return this.query(() => true);
	}

	queryFromAssetId(assetId: TUint64)
	{
		return this.query(it => it.assetId > assetId);
	}

	queryByTemplateId(templateId: number): HeroModel[]
	{
		return this.query(it => it.templateId === templateId);
	}

	queryByRarity(rarity: TRarity)
	{
		return this.query(it => it.rarity === rarity);
	}
}
