import * as React from 'react';
import {AppRoot} from "./src/App";

const appRoot = ({element}) => (
	<AppRoot>
		{element}
	</AppRoot>
)

export default appRoot;
