import { IAssetJson, TRarity, TSchemaName } from "@pro/common/conf";
import { TUint64 } from "@pro/common/contracts/atomicassets";
import { StakeRecord } from "@pro/common/contracts/tribalbooks";
import { action, makeObservable, observable } from "mobx";
import { StakedAssetModel } from "./StakedAssetModel";

export class StakedAssetTable
{
	_items = new Map<TUint64, StakedAssetModel>();

	constructor()
	{
		makeObservable(this, {
			_items: observable,
			resetItems: action,
			updateItem: action,
			removeItem: action,
		});
	}

	resetItems(models: StakedAssetModel[])
	{
		this._items.clear();
		for (const model of models)
			this._items.set(model.assetId, model);
	}

	updateItem(record: StakeRecord, template: IAssetJson)
	{
		let item = this._items.get(record.asset_id);
		if (item) {
			item.update(record);
		} else {
			const model = new StakedAssetModel(record, template);
			this._items.set(record.asset_id, model);
		}
	}

	removeItem(asset_id: TUint64)
	{
		this._items.delete(asset_id);
	}

	query(predicate: (it: StakedAssetModel) => boolean): StakedAssetModel[]
	{
		let result = [];
		for (let item of this._items.values()) {
			if (predicate(item))
				result.push(item);
		}
		return result;
	}

	queryAll(): StakedAssetModel[]
	{
		return this.query(() => true);
	}

	queryBySchemaAndRarity(schemaName: TSchemaName, rarity: TRarity): StakedAssetModel[]
	{
		return this.query(it => it.schemaName === schemaName && it.rarity === rarity);
	}
}