const DEFAULT_WALLET = "None";
const tag = "[LocalData]";

export enum EOSAuthType
{
	Scatter = "Scatter",
	WaxCloud = "WaxCloud",
	None = "None"
}

export class LocalData
{
	private _userName: string = "";
	private _usedAuth: string = "";
	private _pubKeys: string[] = [];
	private _referrer: string = "";
	private _referrerDate: number = 0;

	init()
	{
		console.info(tag, "init");
		let storage = window.localStorage;
		this._userName = storage.getItem("user_name") || "";
		this._usedAuth = storage.getItem("used_wallet") || DEFAULT_WALLET;
		this._pubKeys = storage.getItem("pub_keys")?.split(" ") || [];
		this._referrer = storage.getItem("referrer") || "";
		this._referrerDate = Number(storage.getItem("referrerDate")) || 0;
	}

	flush()
	{
		console.log(tag, "flush");
		let storage = window.localStorage;
		storage.setItem("user_name", this._userName);
		storage.setItem("used_wallet", this._usedAuth);
		storage.setItem("pub_keys", this._pubKeys.join(" "));
		storage.setItem("referrer", this._referrer);
		storage.setItem("referrerDate", String(this._referrerDate));
	}

	logout()
	{
		console.log(tag, "logout");
		this._userName = "";
		this.usedAuth = EOSAuthType.None;
		this.flush();
	}

	get userName() { return this._userName; }

	set userName(value: string)
	{
		this._userName = value;
	}

	get usedAuth(): EOSAuthType
	{
		let w = EOSAuthType[this._usedAuth as keyof typeof EOSAuthType];
		return w;
	}

	set usedAuth(value: EOSAuthType)
	{
		this._usedAuth = value;
	}

	get pubKeys(): string[]
	{
		return this._pubKeys;
	}

	set pubKeys(value: string[])
	{
		this._pubKeys = value;
	}

	get referrer() { return this._referrer }
	set referrer(value: string)
	{
		this._referrer = value;
	}

	get referrerDate() { return this._referrerDate }
	set referrerDate(value: number)
	{
		this._referrerDate = value;
	}
}
