import { Wax } from "@eosdacio/ual-wax";
import { AssetConf, ChainConf, ITemplateJson, TProjEnv } from "@pro/common/conf";
import templates from "@pro/common/data/templates.json";
import React from "react";
import { store } from "react-notifications-component";
import { Anchor } from "ual-anchor";
import { UALProvider } from "ual-reactjs-renderer";
import { Scatter } from "ual-scatter";
import { Authenticator, Chain } from "universal-authenticator-library";
import UalView from "./components/UalView";
import { EosService } from "./eos/EosService";
import { L } from "./l10n/L10n";
import { AtomicService } from "./services/AtomicService";
import { AudioService } from "./services/AudioService";
import { BackendService } from "./services/BackendService";
import { UalService } from "./services/UalService";
import { WorldService } from "./services/WorldService";
import { AppStore } from "./stores/AppStore";
import { LocalData } from "./stores/LocalData";

export class App
{
	readonly nodeos_urls: string[] = process.env.GATSBY_NODEOS_URLS?.split(" ") || [];
	readonly backendUrl = process.env.GATSBY_BACKEND_URL || "http://localhost:3000";
	readonly isDevAuth = process.env.GATSBY_IS_DEV_AUTH === "true";
	readonly projEnv = process.env.GATSBY_PROJ_ENV as TProjEnv;
	readonly dropIds = process.env.GATSBY_DROP_IDS?.split(" ").map(it => parseInt(it)) ?? [];
	readonly chainId = process.env.GATSBY_CHAIN_ID
	                   || "cf057bbfb72640471fd910bcb67639c22df9f92470936cddc1ade0e2f2e7dc4f";
	private _authenticators = new Array<Authenticator>();
	readonly chains: Chain[];
	readonly chainConf = ChainConf.get(this.projEnv);
	readonly assetConf = new AssetConf(templates as ITemplateJson[]);

	readonly localData = new LocalData();
	readonly store = new AppStore();
	readonly eos = new EosService(this.nodeos_urls, this.chainConf);
	readonly atomicService = new AtomicService();
	readonly worldService = new WorldService();
	readonly backendService = new BackendService(this.backendUrl);
	readonly ualService = new UalService();

	private _audioService!: AudioService;

	constructor()
	{
		const rpcEndpoints = this.nodeos_urls.map(nodeosUrl => {
			const [protocol, hostPort] = nodeosUrl.split("://");
			let [host, port] = hostPort.split(":");
			if (!port)
				port = "443";
			return {protocol, host, port: Number(port)};
		});
		this.chains = [{chainId: this.chainId, rpcEndpoints}];
	}

	async init()
	{
		this.initAuthenticators();
		this.localData.init();
		this.applyReferrer();
		await this.eos.mustConnect();
		await this.atomicService.init(this.projEnv);
		await ualService.devAuth();
		this._audioService = new AudioService();
	}

	initAuthenticators()
	{
		this._authenticators = app.projEnv === "prod"
			? [
				new Wax(app.chains),
				new Anchor(app.chains, {appName: L.siteName}),
				// new Wombat(app.chains, {appName: L.siteName}),
			]
			: [
				new Anchor(app.chains, {appName: L.siteName}),
				new Scatter(app.chains, {appName: L.siteName}),
			];
	}

	applyReferrer()
	{
		const url = new URL(window.location.href);
		const urlParams = url.searchParams;
		const referrer = urlParams.get("ref") || "";
		if (!referrer)
			return;

		let referrerDate = this.localData.referrerDate;
		let nowSeconds = Math.floor(Date.now() / 1000);
		let expirationTime = 120 /* days */ * 24 * 3600;

		if (nowSeconds - referrerDate > expirationTime) {
			this.localData.referrer = referrer;
			this.localData.referrerDate = nowSeconds;
			this.localData.flush();
		}
	}

	showInfo(message: string)
	{
		store.addNotification({
			message: message,
			type: "success",
			container: "top-right",
			animationIn: ["animate__animated", "animate__fadeIn"],
			animationOut: ["animate__animated", "animate__fadeOut"],
			dismiss: {
				duration: 5000,
				onScreen: true,
			},
		});
	}

	showError(message: string)
	{
		store.addNotification({
			message: message,
			type: "danger",
			container: "top-right",
			animationIn: ["animate__animated", "animate__fadeIn"],
			animationOut: ["animate__animated", "animate__fadeOut"],
			dismiss: {
				duration: 5000,
				onScreen: true,
			},
		});
	}

	get authenticators(): Authenticator[]
	{
		return this._authenticators;
	}

	get audioService(): AudioService
	{
		return this._audioService;
	}
}

export const app = new App();

if (typeof window !== "undefined") {
	app.init().catch();
}

export const eos = app.eos;
export const appStore = app.store;
export const localStore = app.localData;
export const world = app.worldService;
export const ualService = app.ualService;
export const useApp = () => app;

export const AppRoot = ({children}: {children: React.ReactNode}) => {
	return (
		<UALProvider
			chains={app.chains}
			authenticators={app.authenticators}
			appName={L.siteName}
		>
			<UalView/>
			{children}
		</UALProvider>
	);
};
