import { TProjEnv } from "@pro/common/conf";
import { ExplorerApi } from "atomicassets";
import { IAsset } from "atomicassets/build/API/Explorer/Objects";

const tag = "[atomic]";

const WAX_MAINNET_URL = "https://wax-aa.eu.eosamsterdam.net";
// const WAX_MAINNET_URL = "https://wax.api.atomicassets.io";
const WAX_TESTNET_URL = "https://test.wax.api.atomicassets.io";
const NAMESPACE = "atomicassets";

export class AtomicService
{
	private _isEnabled = false;
	private _api?: ExplorerApi;

	init(projEnv: TProjEnv)
	{
		switch (projEnv) {
			case "dev":
			case "stage":
				this._isEnabled = false;
				break;
			case "testnet":
				this._isEnabled = true;
				this._api = new ExplorerApi(WAX_TESTNET_URL, NAMESPACE, {fetch: fetch as any});
				break;
			case "prod":
				this._isEnabled = true;
				this._api = new ExplorerApi(WAX_MAINNET_URL, NAMESPACE, {fetch: fetch as any});
				break;
		}
	}

	async getAssets(account: string, collectonName: string): Promise<IAsset[]>
	{
		this.ensureIsEnabled();

		const limit = 200;

		let assets: IAsset[] = [];
		let isFinished = false;

		for (let page = 1; !isFinished; page++) {
			console.log(tag, "<- getAssets");
			let chunk = await this._api!.getAssets({
				owner: account,
				collection_name: collectonName,
				page, limit,
			});
			console.log(tag, `-> [${chunk.length}] records`);
			assets = assets.concat(chunk);
			isFinished = chunk?.length < limit;
		}

		return assets;
	}

	private ensureIsEnabled()
	{
		if (!this._isEnabled)
			throw new Error("AtomicService is not enabled!");
	}

	get isEnabled()
	{
		return this._isEnabled;
	}
}
