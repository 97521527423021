import { IChainConf } from "@pro/common/conf";
import { AAContract, TUint64 } from "@pro/common/contracts/atomicassets";
import { ADContract } from "@pro/common/contracts/atomicdropsx";
import { TBContract } from "@pro/common/contracts/tribalbooks";
import { ManaContract } from "@pro/common/contracts/tribalbooks/mana_contract";
import { EosApi, EosAsset, EosSymbol, TTransactImpl } from "@pro/common/eos";
import { TFetch } from "@pro/common/eos/fetch_types";
import { sleep } from "@pro/common/utils/promises";
import { TransactConfig, TransactResult } from "eosjs/dist/eosjs-api-interfaces";
import { Authorization } from "eosjs/dist/eosjs-serialize";
import { ScatterUser } from "ual-scatter";
import { WombatUser } from "ual-wombat";
import { User } from "universal-authenticator-library";

const tag = "[eos]";

export class EosService
{
	public readonly eosApi: EosApi;

	private readonly _adContract: ADContract;
	private readonly _aaContract?: AAContract;
	private readonly _tbContract?: TBContract;
	private _manaContract?: ManaContract;

	private _userName = "";
	private _userAuth: Authorization = {actor: "", permission: "active"};

	private _chainId = "cf057bbfb72640471fd910bcb67639c22df9f92470936cddc1ade0e2f2e7dc4f";
	private _transactImpl: TTransactImpl = () => { throw Error("transactImp is not implemented");};

	constructor(private urls: string[],
	            private chainConf: IChainConf)
	{
		console.info(tag, "init");

		this.eosApi = new EosApi(urls, global.fetch as unknown as TFetch, {
			logRequest: true,
			logResponse: true,
			transactImpl: (transaction: any, config?: TransactConfig) => this._transactImpl(transaction, config),
		});

		this._adContract = new ADContract(this.eosApi, this.chainConf.AD_ACCOUNT);
		this._aaContract = new AAContract(this.eosApi, this.chainConf.AA_ACCOUNT);
		this._tbContract = new TBContract(this.eosApi, this.chainConf.TB_ACCOUNT);
		this._manaContract = new ManaContract(this.eosApi, this.chainConf.MN_ACCOUNT);
	}

	async mustConnect()
	{
		let delay = 2_000;

		// eslint-disable-next-line no-constant-condition
		while (true) {
			try {
				console.log(tag, `<- connecting to ${this.nodeUrl}`);
				let info = await this.eosApi.getInfo();
				console.log(tag, "-> connected");
				this._chainId = info.chain_id;
				console.log(tag, "server_version: " + info.server_version);
				console.log(tag, "head_block_num: " + info.head_block_num);
				console.log(tag, "chain_id: " + this._chainId);
				return;
			} catch (e) {
				console.error(tag, e);
				console.error("Connection error, retrying...");
				if (delay < 10_000) {
					delay += 2_000;
				}
				await sleep(delay);
			}
		}
	}

	async loginWithUal(ualUser: User)
	{
		this._userName = await ualUser.getAccountName();
		this._userAuth.actor = this._userName;

		this._transactImpl = async (transaction: any, config?: TransactConfig): Promise<any> => {
			try {
				if (ualUser instanceof ScatterUser || ualUser instanceof WombatUser) {
					config = {...{requiredKeys: await (ualUser as ScatterUser).getKeys()}, ...config};
				}

				const result = await ualUser.signTransaction(transaction, config);
				return result.transaction;
			} catch (e) {
				if (e.message === "Cannot read property '0' of undefined")
					throw new Error("Transaction signing error");
				throw e;
			}
		};
	}

	async transfer(assetId: TUint64, memo: string)
	{
		console.log("transfer asset");
		if (!this._aaContract) {
			throw "Eos Action Error";
		}
		const title = `transfer: ${assetId} -> ${this.chainConf.TB_ACCOUNT}`;
		console.log(tag, `<- ${title}`);

		const result = await this._aaContract.action("transfer", this._userAuth, {
			from: this._userName,
			to: this.chainConf.TB_ACCOUNT,
			asset_ids: [assetId],
			memo: memo,
		});

		console.log(tag, `-> ${title}, status: ${result.processed.receipt?.status}`);
	}

	async transferToken(code: string, recipient: string, quantity: EosAsset, memo: string = "")
	{
		const title = `transfer: ${quantity.toString()} -> ${recipient}`;

		console.log(tag, `<- ${title}`);

		const result = await this.eosApi.transact<TransactResult>(
			[{
				account: code,
				name: "transfer",
				authorization: [this._userAuth],
				data: {
					from: this._userName,
					to: recipient,
					quantity: quantity.toString(),
					memo: memo,
				},
			}],
			{
				blocksBehind: 3,
				expireSeconds: 60,
			});

		console.log(tag, `-> ${title}, status: ${result ? result.processed.receipt?.status : result}`);
	}

	async buyPack(transferQuantity: EosAsset, claimAmount: number, dropId: TUint64, referrer: string)
	{
		const title = `buy_pack wax:${transferQuantity.toString()}, amaunt:${claimAmount}, drop:${dropId}, ref:${referrer}`;

		console.log(tag, `<- ${title}`);

		const result = await this.eosApi.transact<TransactResult>([
			{
				account: "eosio.token",
				name: "transfer",
				authorization: [this._userAuth],
				data: {
					from: this._userName,
					to: this.chainConf.AD_ACCOUNT,
					quantity: transferQuantity.toString(),
					memo: "deposit",
				},
			},
			{
				account: this.chainConf.AD_ACCOUNT,
				name: "claimdrop",
				authorization: [this._userAuth],
				data: {
					claim_amount: claimAmount,
					claimer: this._userName,
					drop_id: dropId,
					intended_delphi_median: 0,
					referrer: referrer,
				},
			},
		]);

		console.log(tag, `-> ${title}, status: ${result ? result.processed.receipt?.status : result}`);
	}

	async claimMana(asset_id: TUint64)
	{
		await this.tbContract.action("claimmana", this._userAuth, {
			owner: this._userName,
			asset_id: asset_id,
		});
	}

	async unstakeAsset(asset_id: TUint64)
	{
		await this.tbContract.action("unstake", this._userAuth, {
			owner: this._userName,
			asset_id: asset_id,
		});
	}

	async getBalance(account: string, contract: string = "eosio.token", symbol: EosSymbol = this.chainConf.SYS_TOKEN): Promise<EosAsset>
	{
		return this.eosApi.getBalance(contract, account, symbol);
	}

	get nodeUrl(): string
	{
		return this.urls[0];
	}

	get adContract(): ADContract
	{
		return this._adContract;
	}

	get aaContract(): AAContract
	{
		if (!this._aaContract) {
			throw "aaContract is not initialized";
		}

		return this._aaContract;
	}

	get tbContract(): TBContract
	{
		if (!this._tbContract) {
			throw "tbContract is not initialized";
		}

		return this._tbContract;
	}
}
