import { IPackJson } from "@pro/common/conf";
import { TUint64 } from "@pro/common/contracts/atomicassets";
import { IAssetData } from "./AssetData";

export class PackModel
{
	private readonly _assetId: TUint64;
	private readonly _name: string;
	private readonly _cardsQuantity: number;
	private readonly _img: string;

	constructor(record: IAssetData, data: IPackJson)
	{
		this._assetId = record.asset_id;
		this._name = data.name;
		this._cardsQuantity = data.cards_quantity;
		this._img = data.img;
	}

	get assetId()
	{
		return this._assetId;
	}

	get name()
	{
		return this._name;
	}

	get cardsQuantity()
	{
		return this._cardsQuantity;
	}

	get img()
	{
		return this._img;
	}
}
