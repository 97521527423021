import { IAssetJson } from "@pro/common/conf";
import { TUint64 } from "@pro/common/contracts/atomicassets";
import { sleep } from "@pro/common/utils";
import { app, appStore, eos } from "../App";
import { IAssetData } from "../stores/AssetData";
import { StakedAssetModel } from "../stores/StakedAssetModel";

const NUM_OF_RETRIES = 120;
const SLEEP_TIME = 500;

export class WorldService
{
	async updatePackAndAsset()
	{
		await this.updateAssets();
		await this.updatePack();
	}

	async updatePack()
	{
		let packRecord = await eos.tbContract.findPack(app.store.userName);
		if (packRecord) {
			let assetRecord = await eos.aaContract.findAsset(app.chainConf.TB_ACCOUNT, packRecord.asset_id);
			if (assetRecord) {
				appStore.setUnpacking(packRecord, assetRecord);
			}
		}
	}

	async mustUpdatePacks()
	{
		for (let i = 0; i < NUM_OF_RETRIES; i++) {
			let packRecord = await eos.tbContract.findPack(app.store.userName);
			if (packRecord) {
				let assetRecord = await eos.aaContract.findAsset(app.chainConf.TB_ACCOUNT, packRecord.asset_id);
				if (assetRecord) {
					appStore.setUnpacking(packRecord, assetRecord);
				}
				break;
			} else {
				await sleep(SLEEP_TIME);
			}
		}
	}

	async getAssetData(owner: string, collection: string): Promise<IAssetData[]>
	{
		if (app.atomicService.isEnabled) {
			return (await app.atomicService.getAssets(owner, collection))
				.map(it => ({
					asset_id: it.asset_id,
					collection_name: it.collection.collection_name,
					schema_name: it.schema.schema_name,
					template_id: Number(it.template?.template_id ?? -1),
				}));
		} else {
			return (await eos.aaContract.getAllAssets(owner))
				.filter(it => it.collection_name === collection);
		}
	}

	async updateStakedAsset(asset_id: TUint64)
	{
		let asset = await eos.tbContract.getStakedAsset(app.store.userName, asset_id);
		let template = app.assetConf.getByTemplateId<IAssetJson>(asset.template_id);
		app.store.stakedAssets.updateItem(asset, template);
	}

	async updateStakedAssets()
	{
		const records = await eos.tbContract.getStakedAssets(app.store.userName);
		const models = records.map(record => {
			const template = app.assetConf.getByTemplateId<IAssetJson>(record.template_id);
			const model = new StakedAssetModel(record, template);
			return model;
		})
		app.store.stakedAssets.resetItems(models);
	}

	async updateAssets()
	{
		let owner = app.store.userName;
		let assets = await this.getAssetData(owner, app.chainConf.COLLECTION_NAME);
		appStore.setAssets(assets);
	}

	async updateBalance(contract = "eosio.token", symbol = app.chainConf.SYS_TOKEN)
	{
		let balance = await eos.getBalance(app.store.userName, contract, symbol);
		appStore.setBalance(balance);
	}

	async mustUpdateBalance(contract = "eosio.token", symbol = app.chainConf.SYS_TOKEN)
	{
		for (let i = 0; i < NUM_OF_RETRIES; i++) {
			let balance = await eos.getBalance(app.store.userName, contract, symbol);
			if (balance.amount !== app.store.balance) {
				appStore.setBalance(balance);
				break;
			} else {
				await sleep(SLEEP_TIME);
			}
		}
	}

	async updatePoolBalance()
	{
		let balance = await eos.getBalance(app.chainConf.TB_ACCOUNT, app.chainConf.MN_ACCOUNT, app.chainConf.MANA_TOKEN);
		appStore.setPoolBalance(balance);
	}

	async mustUpdatePoolBalance()
	{
		for (let i = 0; i < NUM_OF_RETRIES; i++) {
			let balance = await eos.getBalance(app.chainConf.TB_ACCOUNT, app.chainConf.MN_ACCOUNT, app.chainConf.MANA_TOKEN);
			if (balance.amount !== app.store.poolBalance) {
				appStore.setPoolBalance(balance);
				break;
			} else {
				await sleep(SLEEP_TIME);
			}
		}
	}

	async updateUnpackedAssets(lastAssetId: string | number)
	{
		const unpacking = app.store.unpacking;
		if (!unpacking)
			return;

		for (let i = 0; i < NUM_OF_RETRIES; i++) {
			let assets = await eos.aaContract.getAllAssets(app.store.userName, {lower_bound: lastAssetId});
			appStore.setAssets(assets);

			const symbols = app.store.symbols.queryFromAssetId(lastAssetId);
			const books = app.store.books.queryFromAssetId(lastAssetId);
			const heroes = app.store.heroes.queryFromAssetId(lastAssetId);

			if (symbols.length === 0 && books.length === 0 && heroes.length === 0) {
				console.log("sleep");
				await sleep(SLEEP_TIME);
			} else {
				console.log("receive new symbols:", symbols.length, ", books:", books.length);
				unpacking.setObtainedAsset(symbols, books, heroes);
				return;
			}
		}
	}

	async updateDrops()
	{
		const dropIds = app.dropIds;

		for (let i = 0; i < dropIds.length; i++) {
			let dropId = dropIds[i];
			const dropRecord = await eos.adContract.findDrop(dropId);
			if (dropRecord !== undefined) {
				app.store.drops.updateItem(dropRecord);
			}
		}
	}
}
