import { IAssetJson } from "@pro/common/conf";
import { StakeRecord } from "@pro/common/contracts/tribalbooks";
import { action, makeObservable, observable } from "mobx";
import { app } from "../App";

export class StakedAssetModel
{
	_record!: StakeRecord;
	_template: IAssetJson;

	constructor(_record: StakeRecord, template: IAssetJson)
	{
		this.update(_record);

		this._template = template;

		makeObservable(this, {
			_record: observable,
			update: action,
		});
	}

	update(record: StakeRecord)
	{
		this._record = record;
	}

	get assetId()
	{
		return this._record.asset_id;
	}

	get templateId()
	{
		return this._record.template_id;
	}

	get lastClaimTime()
	{
		return this._record.claim_time;
	}

	get actionAvailable()
	{
		return this._record.claim_time + app.chainConf.CLAIM_INTERVAL_SECS < Math.floor(Date.now() / 1000);
	}

	get secondsToAction():number
	{
		return Math.max(0,this._record.claim_time + app.chainConf.CLAIM_INTERVAL_SECS - Math.floor(Date.now() / 1000));
	}

	get power()
	{
		return this._record.power;
	}

	get schemaName()
	{
		return this._template.schema_name;
	}

	get name()
	{
		return this._template.name;
	}

	get img()
	{
		return this._template.img;
	}

	get rarity()
	{
		return this._template.rarity;
	}
}