import { TUint64 } from "@pro/common/contracts/atomicassets";
import { DropRecord } from "@pro/common/contracts/atomicdropsx";
import { action, makeObservable, observable } from "mobx";
import { DropModel } from "./DropModel";

export class DropTable
{
	_items = new Map<TUint64, DropModel>();

	constructor()
	{
		makeObservable(this, {
			_items: observable,
			updateItem: action,
		});
	}

	updateItem(record: DropRecord)
	{
		let item = this._items.get(record.drop_id);
		if (item) {
			item.update(record);
		} else {
			const model = new DropModel(record);
			this._items.set(record.drop_id, model);
		}
	}

	query(predicate: (it: DropModel) => boolean): DropModel[]
	{
		let result = [];
		for (let item of this._items.values()) {
			if (predicate(item))
				result.push(item);
		}
		return result;
	}

	queryAll(): DropModel[]
	{
		return this.query(() => true);
	}
}