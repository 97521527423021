import { IAssetJson } from "@pro/common/conf";
import { TUint64 } from "@pro/common/contracts/atomicassets";
import { IAssetData } from "./AssetData";

export class BookModel
{
	private readonly _assetId: TUint64;
	private readonly _schemName: string;
	private readonly _templateId: number;
	private readonly _name: string;
	private readonly _rarity: string;
	private readonly _img: string;

	constructor(record: IAssetData, data: IAssetJson)
	{
		this._assetId = record.asset_id;
		this._schemName = data.schema_name;
		this._templateId = record.template_id;
		this._name = data.name;
		this._rarity = data.rarity;
		this._img = data.img;
	}

	get assetId()
	{
		return this._assetId;
	}

	get achemaName()
	{
		return this._schemName;
	}

	get templateId()
	{
		return this._templateId;
	}

	get name()
	{
		return this._name;
	}

	get rarity()
	{
		return this._rarity;
	}

	get img()
	{
		return this._img;
	}
}
